import { Link, navigate } from "gatsby"
import React, { Component } from "react"
import { connect } from "react-redux"
import { DashboardPage } from "../components/authPages/Dashboard"
import Button from "../components/base/Button"
import Footer from "../components/base/Footer"
import Input from "../components/base/Input"
import SEO from "../components/base/seo"
import Header from "../components/dashboard/Header"
import CardSegment from "../components/marketing/CardSegment"
import LeftContentCard from "../components/marketing/LeftContentCard"
import DoorImage from "../images/home_ss14.webp"
import ReferalHistoryImage from "../images/refer_history.webp"
import { authStateInterface } from "../store/reducers/authReducer"
import { Language } from "../store/reducers/utilsReducer"
import { localeContent } from "../utils/locale"
import Notification, {
  NotificationAttribute,
} from "./../components/base/Notification"

interface Props {
  authStore: authStateInterface
}

interface State {
  notificationAttribute: NotificationAttribute
}

const language = Language.EN

class ReferFriend extends Component<Props> {
  timeout: any = null

  state: State = {
    notificationAttribute: {
      title: "",
      desc: "",
      type: "",
      visible: false,
    },
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.authStore.userLoading !== this.props.authStore.userLoading) {
      if (!this.props.authStore.userAuth) {
        navigate("/")
      }
    }
  }

  componentWillUnmount() {
    this.handleClearNotification()
  }

  handleClearNotification = () => {
    this.setState({
      notificationAttribute: {
        title: "",
        desc: "",
        type: "",
        visible: false,
      },
    })
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  handleChangeNotificationVisible = (
    notificationAttribute: NotificationAttribute
  ) => {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
    this.setState(
      {
        notificationAttribute,
      },
      () => {
        this.timeout = setTimeout(() => {
          this.handleClearNotification()
        }, 5000)
      }
    )
  }

  renderReferalForm = () => {
    const referralLink = `https://arusoil.com/referrals?id=${this.props.authStore.user?.id}`
    const referralDescription = `Hi friends,\n\nI am inviting you to recycle used cooking oil with me at Arus Oil. Arus Oil collects your used cooking oil at your doorstep to be converted to bio-diesel (Power industrial lorries etc).\n\nSign up with my referral link ${referralLink} now so that both of us can get 10% increase in the purchasing price once we recycle our used cooking oil.`

    return (
      <>
        <p className="mt-4 text-base leading-7 text-gray-500 text-justify">
          Invite your friend to start recycling used cooking oil with you. Make
          sure they sign up with your unique referral link so that both of you
          will get a 10% increase in the purchasing price of used cooking oil
          once your friend has started recycling once.
        </p>
        <div className="flex flex-col sm:flex-row mt-3">
          <Input
            id="referal_link"
            className="mt-1 bg-white"
            disabled={true}
            value={referralLink}
          />
          <Button
            className="w-full sm:w-auto ml-0 mt-3 sm:ml-3 sm:mt-0"
            text="Copy"
            type="normal"
            onClick={() => {
              navigator.clipboard.writeText(referralDescription)
              this.handleChangeNotificationVisible({
                type: "success",
                visible: true,
                title: "Referral Link Copied",
                desc: `Your referral link has been copied to your clipboard. Share this now!`,
              })
            }}
          />
        </div>
      </>
    )
  }

  renderCTA = () => {
    return (
      <div className="relative py-16 bg-gray-50">
        <div
          className="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block"
          aria-hidden="true"
        />
        <div className="max-w-7xl mx-auto bg-arusgreen-500 lg:bg-transparent lg:px-8">
          <div className="lg:grid lg:grid-cols-12">
            <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
              <div
                className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
                aria-hidden="true"
              />
              <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                  <img
                    className="object-cover object-center rounded-3xl shadow-2xl"
                    src={ReferalHistoryImage}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="relative bg-arusgreen-500 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
              <div
                className="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-arusgreen-500"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                  />
                </svg>
                <svg
                  className="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-arusgreen-500"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                  />
                </svg>
              </div>
              <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                <h2
                  className="text-3xl font-extrabold text-white"
                  id="join-heading"
                >
                  {localeContent(language).referFriend.referralForm.title}
                </h2>
                <p className="text-lg text-white">
                  {localeContent(language).referFriend.referralForm.description}
                </p>
                <div className="flex flex-row mt-10 mb-4">
                  <Link to="/dashboard/profile">
                    <Button
                      className="w-full sm:max-w-xs"
                      type="light"
                      text={
                        localeContent(language).referFriend.referProgramme
                          .viewReferral
                      }
                    />
                  </Link>
                  <Link to="/referralFaqs">
                    <Button
                      className="ml-2 w-full sm:max-w-xs"
                      type="light"
                      text={
                        localeContent(language).referFriend.referProgramme
                          .viewFaqs
                      }
                    />
                  </Link>
                  <div className="flex-grow" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.authStore.userLoading || !this.props.authStore.userAuth) {
      return null
    }

    return (
      <div className="bg-white">
        <SEO
          title="Arus Oil - Refer A Friend"
          description="Refer a friend to recycle used cooking oil with you at Arus Oil. Both of you will be entitled to a 10% increase when your friend starts recycling."
        />
        <Notification
          notificationAttribute={this.state.notificationAttribute}
          onClick={this.handleClearNotification}
        />
        <Header page={DashboardPage.REFERAFRIEND} />
        <LeftContentCard
          image={DoorImage}
          title="Refer To A Friend"
          content={this.renderReferalForm()}
          background="bg-gray-100"
        />
        <CardSegment
          types={
            localeContent(language).referFriend.cardSegment.referralSegmentPost
          }
          title={localeContent(language).referFriend.cardSegment.title}
        />
        {this.renderCTA()}
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  }
}

export default connect(mapStateToProps)(ReferFriend)
